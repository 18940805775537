import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { IThemeName } from 'types';

// Lazy load this component as render may not ever be called.
const SmartPath = React.lazy(() =>
  import(
    /* webpackChunkName: "smartpath" */
    /* webpackPreload: true */
    './components/smart-path/smart-path'
  )
);

interface IConfig {
  host: string;
  mode?: 'production' | 'builder' | 'development';
  nm: string;
  section: string;
  selector: string;
  sessionId?: string;
  theme?: IThemeName;
}

const defaultConfig: IConfig = {
  host: `sp.${window.location.hostname}`,
  mode: 'production',
  nm: '',
  section: '__default',
  selector: '#smartpath'
};

let baseConfig: IConfig;

export const sp = {
  config(config: IConfig) {
    baseConfig = { ...defaultConfig, ...config };
  },
  new: (config?: IConfig) => {
    return {
      render(args: IConfig) {
        const options: any = {};

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('testMock')) {
          options.testMockName = urlParams.get('testMock');
        }

        let sentryEnv = 'production';
        if (window.location.hostname === 'localhost') {
          sentryEnv = 'development';
        } else if (window.location.hostname.indexOf('staging') > -1) {
          sentryEnv = 'staging';
        }

        if (window.location.hostname !== 'localhost') {
          Sentry.init({
            dsn: 'https://92a1afd7436845fea811339acef8e8c5@o74152.ingest.sentry.io/5231575',
            release: 'smartpathjs@' + __VERSION__,
            environment: sentryEnv
          });

          Sentry.setExtra('host', args?.host || config?.host || baseConfig.host);
          Sentry.setExtra('nm', args?.nm || config?.nm || baseConfig.nm);
          Sentry.setExtra('mode', args?.mode || config?.mode || baseConfig.mode);
          Sentry.setExtra('theme', args?.theme || config?.theme || baseConfig.theme);
        }

        ReactDOM.render(
          <Suspense fallback={null}>
            <SmartPath
              host={args?.host || config?.host || baseConfig.host}
              nm={args?.nm || config?.nm || baseConfig.nm}
              mode={args?.mode || config?.mode || baseConfig.mode}
              section={args?.section || config?.section || baseConfig.section}
              sessionId={args?.sessionId || config?.sessionId || baseConfig.sessionId}
              theme={args?.theme || config?.theme || baseConfig.theme}
              version={__VERSION__}
              {...options}
            />
          </Suspense>,
          document.querySelector(config?.selector || baseConfig.selector)
        );
      },
      unmount() {
        ReactDOM.unmountComponentAtNode(
          document.querySelector(config?.selector || baseConfig.selector) as Element
        );
      }
    };
  },
  version: __VERSION__,
  hash: __HASH__
};
